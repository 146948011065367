/*
 * @Author: 黄孝娟
 * @Date: 2019-11-08 14:48:19
 * @LastEditors: 黄孝娟
 * @LastEditTime: 2019-11-08 11:48:06
 */
import axios from '@/lib/api.request'

// 创建活动
export const creadActivity = data =>
  axios.post('/abt/abtComKjYqhSignup/creadActivity', data)
// 获取邀请函图片库 GET {tagcode}
export const selectTKP = params =>
  axios.get('/abt/abtComKjYqhSignup/selectTKP', { params })
// 获取报名信息列表
export const list = data =>
  axios.post('/abt/abtComKjYqhSignup/list', data)
// 报名保存 /abt/abtComKjYqhSignup/add
export const add = data =>
  axios.post('/abt/abtComKjYqhSignup/add', data)
