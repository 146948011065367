<template>
  <div class="content">
    <div class="titleimg">
      <img :src="ossurl+form.pichttp" alt="" class="mainpic" />
      <simple-cropper :initParam="uploadParam" :successCallback="uploadHandle" ref="cropper" class="box">
        <div class="tips imgbtn1"
             :style="`backgroundColor:${colorprimary}`"
	     @click="upload('cropper')">上传我的</div>
      </simple-cropper>
      <div class="tips imgbtn2"
           :style="`backgroundColor:${colorprimary}`"
	   @click="showPopup">精选图片</div>
    </div>
    <div class="activity">
      <div class="item">
        <span>活动名称<span class="red"> * </span>：</span>
        <input v-model="form.stitle" type="text" placeholder="--请输入--"/>
      </div>
      <div class="item">
        <span>活动时间<span class="red"> * </span>：</span>
        <input @click="openData" readonly v-model="form.fbtime" type="text" placeholder="--请输入--"/>
        <md-date-picker
          ref="datePicker"
          type="datetime"
          :default-date="currentDate"
          v-model="isShowData"
          keep-index
          @confirm="onDatePickerConfirm"
        ></md-date-picker>
      </div>
      <div class="item">
        <span>活动地点<span class="red"> * </span>：</span>
        <input v-model="form.conthttp" type="text" placeholder="--请输入--"/>
      </div>
    </div>
    <div class="activity">
      <div class="item">
        <span>联系人<span class="red"> * </span>：</span>
        <input v-model="form.autor" type="text" placeholder="--请输入--"/>
      </div>
      <div class="item">
        <span>联系电话<span class="red"> * </span>：</span>
        <input maxlength="11"
               v-model="form.remark"
               @click="openKeyboard"
               type="text"
               oninput="value=value.replace(/[^\d]/g,'')"
               placeholder="--请输入--"/>
        <!-- <md-number-keyboard
          v-model="isKeyBoardShow"
          :text-render="keyFormatter"
          @enter="onNumberEnter"
          @delete="onNumberDelete"
        ></md-number-keyboard> -->
      </div>
      <div class="item">
        <span>活动描述<span class="red"> * </span>：</span>
        <textarea v-model="form.sdesc" maxlength="500"></textarea>
      </div>
    </div>
    <div class="activity">
      <div class="item">
        <span>活动说明<span class="red"> * </span>：</span>
        <textarea v-model="form.smark" maxlength="500"></textarea>
      </div>
    </div>
    <div class="tip">
      <span>
        温馨提示：您的客户可在邀请页面点击"报名参加"进行报名，您可在"我的邀请函"中查询报名情况。
      </span>
    </div>
    <div class="bottombtn">
      <div class="imgbtn"
           :style="`backgroundColor:${colorprimary}`"
	   @click="creadActivity">创建活动</div>
    </div>
    <md-popup
      class="inner-popup"
      v-model="isPopupShow"
      position="bottom"
    >
      <md-popup-title-bar
        only-close
        large-radius
        :mask-closable="false"
        @cancel="hidePopUp()"
      ></md-popup-title-bar>
      <div class="md-example-popup md-example-popup-align">
          <div class="popup">
            <div class="title1">
              <span>选择封面图</span>
            </div>
            <div class="title2">
              <span>SELECT THE COVER CHART</span>
            </div>
            <div class="picBase">
              <div class="picItem"
                v-for="(item,index) in picLists"
                :key="index"
                @click="useBasePic(item)">
                <img class="basepic" :src="ossurl+item" />
                <span :style="`backgroundColor:${colorprimary}`">点我使用</span>
              </div>
            </div>
          </div>
      </div>
    </md-popup>
  </div>
</template>
<script>
import { selectOneBySno } from '@/api/abt/customerOperation/common'
import { creadActivity, selectTKP } from '@/api/abt/customerOperation/invitationletter'
import simpleCropper from '../visitingcard/component/simpleCropper'
import { getStorage } from '@/lib/util'
import { Toast, NumberKeyboard } from 'mand-mobile'
export default {
  components: {
    [NumberKeyboard.name]: NumberKeyboard,
    simpleCropper
  },
  data () {
    return {
      ossurl: '',
      user: '',
      pichttp: '',
      sno: '',
      uploadParam: {
        file: 'YQH',
        type: 'YQH',
        fileType: 'recruit', // 其他上传参数
        uploadURL: '/empno/getUpload', // 上传地址
        scale: 4, // 相对手机屏幕放大的倍数: 4倍
        ratio: 5 / 3
        // userid: localStorage.getItem(window.g.beforestr + '_userid'),
        // comid: localStorage.comid
      }, // 上传头像初始化数据
      currentDate: new Date(),
      isShowData: false,
      form: {
        osno: '',
        stitle: '',
        smark: '',
        sdesc: '',
        pichttp: '',
        fbtime: '',
        conthttp: '',
        autor: '',
        remark: ''
      },
      sharecode: '',
      isPopupShow: false,
      picLists: [],
      isKeyBoardShow: false,
      number: '',
      colorprimary: ''
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.colorprimary = getStorage('theme', '')
    this.form.pichttp = this.$route.query.pichttp
    this.sno = this.$route.query.sno
    this.getData()
    this.height = document.body.clientHeight
  },
  mounted () {
    document.querySelector('.content').style.height = `${this.height}px`
    $(document).on('focusout', function () {
      setTimeout(() => {
        // 软键盘收起的事件处理
        window.scroll(0, 0)
      }, 300)
    })
  },
  methods: {
    getData () {
      selectOneBySno({ btagcode: '5', sno: this.sno, flag: '0' }).then(res => {
        this.form.osno = res.data.data.osno
        this.form.stitle = res.data.data.stitle
        this.form.smark = res.data.data.smark
        this.form.sdesc = res.data.data.sdesc
        // this.form.pichttp = res.data.data.pichttp
        this.form.stagcode = res.data.data.stagcode
      }).catch(() => {
        this.form.stagcode = this.$route.query.stagcode
      })
    },
    // 上传图片
    upload (ele) {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        console.log(this.$refs[ele].upload())
      }
      this.$refs[ele].upload()
    },
    // 上传图片成功回调
    uploadHandle (data) {
      this.form.pichttp = data.path
      Toast.succeed('上传成功')
    },
    showPopup () {
      this.isPopupShow = true
      selectTKP({ tagcode: this.form.stagcode }).then(res => {
        this.picLists = res.data.data
      })
    },
    hidePopUp () {
      this.isPopupShow = false
    },
    openData () {
      this.isShowData = true
    },
    onDatePickerConfirm (columnsValue) {
      this.form.fbtime = this.$refs.datePicker.getFormatDate('yyyy-MM-dd hh:mm')
    },
    creadActivity () {
      if (this.form.stitle === '') {
        Toast.info('请填写活动名称')
        return
      }
      if (this.form.fbtime === '') {
        Toast.info('请填写活动时间')
        return
      }
      if (this.form.conthttp === '') {
        Toast.info('请填写活动地点')
        return
      }
      if (this.form.autor === '') {
        Toast.info('请填写联系人')
        return
      }
      if (this.form.remark === '') {
        Toast.info('请填写联系电话')
        return
      }
      if (this.form.sdesc === '') {
        Toast.info('请填写活动描述')
        return
      }
      if (this.form.smark === '') {
        Toast.info('请填写活动说明')
        return
      }
      creadActivity(this.form).then(res => {
        Toast.succeed('创建成功')
        this.sharecode = res.data.data
        this.$router.push(
          { name: 'invite', query: { sno: this.sharecode, empno: this.user.empno, suserid: this.user.userid } }
        )
      })
    },
    openKeyboard () {
      this.isKeyBoardShow = true
    },
    keyFormatter (val) {
      if (val === '.') {
        return 'x'
      }
    },
    onNumberEnter (val) {
      if (this.form.remark.length < 11) {
        this.form.remark += val
      }
    },
    onNumberDelete () {
      if (this.form.remark === '') {
        return
      }
      this.form.remark = this.form.remark.substr(0, this.form.remark.length - 1)
    },
    useBasePic (item) {
      this.form.pichttp = item
      this.isPopupShow = false
    }
  }
}
</script>
<style lang="stylus" scoped>
.content
  background-color #f3f3f3;
  padding-bottom 100px
  overflow-y auto
  overflow-x hidden
.titleimg
  position relative
  .imgbtn1
    position absolute
    top: 200px
  .imgbtn2
    position absolute
    top: 300px
.activity
  background-color #fff;
  margin-bottom 20px
  .item
    padding 30px 60px
    .red
      color red
    input
      outline none
      border none
      font-size 38px
    span
      font-weight 800
      font-size 38px
    textarea
      width 100%
      margin-top 30px
      height 250px
      border-radius 15px
      border: 1px solid #e0e0e0;
      padding 20px 30px
      font-size 38px
.tip
  background-color #f3f3f3;
  padding 30px 50px
  span
    font-size 30px
    color #999
// .bottombtn
//   position: fixed;
//   z-index: 100;
//   bottom: 0;
//   width: 100%;

.bottombtn
  position fixed
  bottom 0
  width 100%
  .imgbtn
    text-align center
    padding 20px 30px
    width: 100%;
    // background-color color-primary
    color #fff
    border-radius 10px
    font-size 40px
.popup
  .title1
    width: 100%;
    text-align: center;
    span
      color: rgb(1, 81, 132);
      font-size: 80px;
  .title2
    text-align: center;
    width: 100%;
    padding-top 20px
    padding-bottom 80px
    span
      color: rgb(1, 81, 132);
      font-size: 30px;
  .picBase
    height 600px
    overflow-y auto
    display flex
    flex-wrap wrap
    padding-left 20px
    padding-right 20px
    .picItem
      margin 20px auto
      width 45%
      position relative
      text-align center
      .basepic
        box-shadow: rgba(18, 126, 221, 0.7) 1px 1px 6px;
      span
        position absolute
        width 100%
        line-height 60px
        bottom 0
        left 0
        color #fff
        //background-color color-primary
.tips
  position: fixed;
  right: 0;
  bottom: 3rem;
  width: 1.6rem;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: right;
  padding: 0 0.1rem;
  // background-color: color-primary;
  color: white;
  font-weight: bold;
  font-size: 0.3rem;
  border-bottom-left-radius: 1rem;
  -moz-border-radius-bottomleft: 1rem;
  -webkit-border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
  -moz-border-radius-topleft: 1rem;
  -webkit-border-top-left-radius: 1rem;
</style>
